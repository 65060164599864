var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('div',[_c('v-data-table',{key:JSON.stringify(_vm.items),ref:"dataTable",staticClass:"text-no-wrap",attrs:{"items":_vm.items,"headers":_vm.headers(_vm.isMounted),"loading":!_vm.isMounted,"hide-default-footer":"","disable-pagination":"","no-data-text":"データがありません","header-props":{ sortByText: 'ソート' },"height":_vm.onFixedHeader ? _vm.height : 'auto',"fixed-header":_vm.onFixedHeader},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"w-full d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"ripple":false,"color":"secondary","small":"","disabled":!_vm.items || _vm.items.length < 1},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileExcel)+" ")]),_vm._v(" ダウンロード ")],1)],1)]},proxy:true},{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.checkAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.checkAmount.toLocaleString())+" ")]}},{key:"item.userSalesAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.userSalesAmount.replace(/\n/g, '<br>'))}})]}},{key:"item.receiptId",fn:function(ref){
var item = ref.item;
return [(item.receiptId)?_c('v-chip',{attrs:{"small":""},on:{"click":function($event){return _vm.getReceipt(item.receiptId)}}},[(_vm.isLoadingReceipt && +_vm.receipt.id === +item.receiptId)?_c('clip-loader',{staticClass:"d-flex align-items pr-2",attrs:{"loading":true,"color":'#8A8D93',"size":"16px"}}):_vm._e(),_vm._v(" 伝票 No."+_vm._s(item.receiptId)+" ")],1):_vm._e()]}},{key:"body.prepend",fn:function(ref){
var _headers = ref.headers;
return [_c('tr',_vm._l((_headers),function(header){return _c('td',{key:((header.value) + "-sum"),class:{
            'v-data-table__divider': header.divider,
          }},[_c('span',{class:{ 'error--text': Number(_vm.accRow[header.text]) && Number(_vm.accRow[header.text]) < 0 }},[_vm._v(" "+_vm._s(Number(_vm.accRow[header.text]) ? Math.abs(_vm.accRow[header.text]) : _vm.accRow[header.text])+" ")])])}),0)]}}],null,true)}),(_vm.receipt && _vm.receipt.id && _vm.receipt.meta && _vm.receipt.attributes)?_c('receipt-show-drawer',{attrs:{"is-visible":!_vm.isLoadingReceipt && _vm.isReceiptOpen,"receipt-id":_vm.receipt.id,"table-name":_vm.receipt.meta.tableName,"start-at":_vm.receipt.meta.tableStartAt,"customer-count":_vm.receipt.meta.tableCustomerCount,"table-id":_vm.receipt.attributes.tableId},on:{"close":_vm.closeReceipt,"deleted":function($event){return _vm.deleteReceipt(_vm.receipt.id)}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }