<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div v-if="items">
    <v-data-table
      ref="dataTable"
      :key="JSON.stringify(items)"
      :items="items"
      :headers="headers(isMounted)"
      :loading="!isMounted"
      hide-default-footer
      disable-pagination
      class="text-no-wrap"
      no-data-text="データがありません"
      :header-props="{ sortByText: 'ソート' }"
      :height="onFixedHeader ? height : 'auto'"
      :fixed-header="onFixedHeader"
    >
      <template #top>
        <div class="w-full d-flex">
          <v-spacer />
          <v-btn
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            :disabled="!items || items.length < 1"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template #[`item.number`]="{index}">
        {{ index + 1 }}
      </template>

      <template #[`item.checkAmount`]="{item}">
        ¥{{ item.checkAmount.toLocaleString() }}
      </template>

      <template #[`item.userSalesAmount`]="{item}">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="item.userSalesAmount.replace(/\n/g, '<br>')" />
      </template>

      <template #[`item.receiptId`]="{item}">
        <v-chip
          v-if="item.receiptId"
          small
          @click="getReceipt(item.receiptId)"
        >
          <clip-loader
            v-if="isLoadingReceipt && +receipt.id === +item.receiptId"
            :loading="true"
            :color="'#8A8D93'"
            size="16px"
            class="d-flex align-items pr-2"
          />
          伝票 No.{{ item.receiptId }}
        </v-chip>
      </template>

      <template #[`body.prepend`]="{headers: _headers}">
        <tr>
          <td
            v-for="header in _headers"
            :key="`${header.value}-sum`"
            :class="{
              'v-data-table__divider': header.divider,
            }"
          >
            <span :class="{ 'error--text': Number(accRow[header.text]) && Number(accRow[header.text]) < 0 }">
              {{ Number(accRow[header.text]) ? Math.abs(accRow[header.text]) : accRow[header.text] }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>

    <receipt-show-drawer
      v-if="receipt && receipt.id && receipt.meta && receipt.attributes"
      :is-visible="!isLoadingReceipt && isReceiptOpen"
      :receipt-id="receipt.id"
      :table-name="receipt.meta.tableName"
      :start-at="receipt.meta.tableStartAt"
      :customer-count="receipt.meta.tableCustomerCount"
      :table-id="receipt.attributes.tableId"
      @close="closeReceipt"
      @deleted="deleteReceipt(receipt.id)"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
  provide,
  toRefs,
} from '@vue/composition-api'
import {
  chain,
  isEqual,
  filter,
  map,
  findIndex,
  each,
} from 'lodash'
import { mdiFileExcel } from '@mdi/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ReceiptApi from '@/api/waiter/Receipt'
import ReceiptShowDrawer from '@/views/components/receipt/ReceiptShowDrawer.vue'
import useTableResultOverviewsDataTable from '@/views/composable/data-table/useTableResultOverviewsDataTable'

export default {
  components: {
    ClipLoader,
    ReceiptShowDrawer,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    dynamicHeaders: {
      type: Array,
      default: () => [],
    },
    height: {
      type: [String, Number],
      default: 604,
    },
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const dataTable = ref()
    const onFixedHeader = ref(false)
    const isLoadingReceipt = ref(false)
    const isReceiptOpen = ref(false)
    const isDownloading = ref(false)
    const receipt = ref({})
    provide('receipt', receipt)

    const isMounted = ref(false)
    onMounted(() => {
      onFixedHeader.value = (+dataTable.value?.$el?.clientHeight || 0) > +props.height

      isMounted.value = true
    })

    const {
      items: tableResultOverviews,
      dynamicHeaders: dynamicTableResultOverviewHeaders,
    } = toRefs(props)
    const { headers, accRow, buildXlsxData } = useTableResultOverviewsDataTable({ tableResultOverviews, dynamicTableResultOverviewHeaders })

    const getReceipt = async id => {
      receipt.value = { id }
      isLoadingReceipt.value = true

      const res = await ReceiptApi.getReceipt(id)

      if (res?.status === 200) {
        receipt.value = { ...res.data.receipt.data }

        isReceiptOpen.value = true
      }

      isLoadingReceipt.value = false
    }

    const closeReceipt = () => {
      isReceiptOpen.value = false
      receipt.value = {}
    }

    const deleteReceipt = id => {
      const idx = findIndex(tableResultOverviews.value, o => +o.receiptId === +id)
      tableResultOverviews.value.splice(idx, 1)

      closeReceipt()

      vm.$toast.success('伝票を削除しました')
    }

    const download = () => {
      isDownloading.value = true

      const itemsExcel = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(itemsExcel)
      XLSX.utils.book_append_sheet(wb, data, '卓概要')

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `卓概要_${title}.xlsx`)

      isDownloading.value = false
    }

    return {
      // data
      dataTable,
      onFixedHeader,
      receipt,
      isLoadingReceipt,
      isReceiptOpen,
      isMounted,

      // computed
      headers,
      accRow,

      // methods
      getReceipt,
      closeReceipt,
      deleteReceipt,
      download,

      icons: {
        mdiFileExcel,
      },
    }
  },
}
</script>

<style lang="scss">
.table-result-overviews-vendible-tag-cells {
  background: rgba(0,0,0,.1);
}
</style>
