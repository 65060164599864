<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div :key="JSON.stringify(incomeDetail)">
    <v-card class="mb-8 daily-report-contents-wrapper">
      <v-card-title class="">
        収支
        <v-divider inset />

        <v-switch
          v-model="onDetailList"
          label="日次"
          hide-details
          class="pa-0 ma-0"
        />
      </v-card-title>

      <v-card-subtitle
        v-if="isDailyReport"
        class="d-flex flex-wrap align-center"
      >
        <v-menu
          v-model="isPickingDate"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="`${dateToAccumulateFrom}~`"
              :prepend-icon="icons.mdiSigma"
              suffix="累計"
              style="max-width: 150px;"
              class="mr-6"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="dateToAccumulateFrom"
            locale="ja"
            :day-format="date => new Date(date).getDate()"
            color="primary"
            no-title
            :max="date"
            @input="isPickingDate = false"
          />
        </v-menu>

        <v-text-field
          v-model.number="daysToAverage"
          :prepend-icon="icons.mdiSlashForwardBox"
          inputmode="numeric"
          pattern="[0-9]*"
          min="1"
          suffix="日平均"
          style="max-width: 146px;"
        >
          <template #append-outer>
            <yona-edit-dialog
              title="定休日"
              @save="saveAllRegularDayOffs"
              @close="resetRegularDayOffsForm"
            >
              <template #append-outer-display-name>
                <v-btn
                  icon
                  small
                  :ripple="false"
                >
                  <v-icon small>
                    {{ icons.mdiCogOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <template #input>
                <v-checkbox
                  v-for="dayOfWeek in daysOfWeek"
                  :key="`${currentClub.slug}-regular-day-off-${dayOfWeek.value}`"
                  v-model="selectedDaysOfWeek"
                  :label="dayOfWeek.label"
                  :value="dayOfWeek.value"
                  hide-details
                  dense
                />
              </template>
            </yona-edit-dialog>
          </template>
        </v-text-field>
      </v-card-subtitle>

      <v-skeleton-loader
        v-if="isLoadingAccContent || isLoadingDetailContent || isLoading"
        type="table"
        class="mx-4"
      />
      <v-card-text v-else>
        <template v-if="!onDetailList">
          <v-row>
            <v-col
              md="6"
              sm="12"
            >
              <app-card-actions class="daily-report-contents-wrapper" action-collapse>
                <template #title>
                  発生
                </template>
                <v-card-text>
                  <detail
                    :key="`detail-${interval}-${dateToAccumulateFrom}-${daysToAverage}`"
                    :profit="finance.profit"
                    :income="finance.income"
                    :spending="finance.spending"
                    :sales-amount="salesAmount"
                    :cash-amount="incomeDetail.cashAmount"
                    :card-amount="incomeDetail.cardAmount"
                    :balance-receivable-amount="incomeDetail.balanceReceivableAmount"
                    :benefits="benefits"
                    :expenses="expenses"
                    :labor-costs="laborCosts"
                    :date="date"
                    :fixed-at="fixedAt"
                    :accumulated-income="acc.income"
                    :accumulated-spending="acc.spending"
                    :days-to-average="Number(daysToAverage) || 1"
                    :is-daily-report="isDailyReport"
                    class="mb-2"
                    @income-updated="getIncome"
                    @spending-updated="getSpending"
                  />
                </v-card-text>
              </app-card-actions>
            </v-col>

            <v-col
              md="6"
              sm="12"
            >
              <app-card-actions class="daily-report-contents-wrapper" action-collapse>
                <template #title>
                  現金
                </template>

                <v-card-text>
                  <money-detail
                    :key="`money-detail-${interval}-${dateToAccumulateFrom}-${daysToAverage}`"
                    :profit="moneyFinance.profit"
                    :money-profit-diff="moneyProfitDiff"
                    :income="moneyFinance.income"
                    :spending="moneyFinance.spending"
                    :sales-amount="moneySalesAmount"
                    :cash-amount="moneyIncomeDetail.cashAmount"
                    :balance-receivable-amount="moneyIncomeDetail.balanceReceivableAmount"
                    :benefit-amount="moneyIncomeDetail.benefitAmount"
                    :labor-cost-amount="moneyLaborCostAmount"
                    :cast-earning-amount="moneySpendingDetail.castEarningAmount"
                    :waiter-earning-amount="moneySpendingDetail.waiterEarningAmount"
                    :alliance-earning-amount="moneySpendingDetail.allianceEarningAmount"
                    :expense-amount="moneySpendingDetail.expenseAmount"
                    :date="date"
                    :accumulated-income="acc.moneyIncome"
                    :accumulated-spending="acc.moneySpending"
                    :days-to-average="Number(daysToAverage) || 1"
                    :is-daily-report="isDailyReport"
                    class="mb-2"
                    read-only
                    @income-updated="getIncome"
                    @spending-updated="getSpending"
                  />
                </v-card-text>
              </app-card-actions>
            </v-col>
          </v-row>

          <v-row v-if="expensesAndBenefits && expensesAndBenefits.length > 0">
            <v-card-text class="d-flex">
              <v-spacer />
              <v-btn
                :ripple="false"
                color="accent"
                @click="show.expensesAndBenefits = !show.expensesAndBenefits"
              >
                <v-icon left>
                  {{ icons.mdiFormatListNumbered }}
                </v-icon>
                諸入費一覧
              </v-btn>
            </v-card-text>

            <v-expand-transition>
              <v-card-text v-show="show.expensesAndBenefits">
                <v-data-table
                  :headers="ExpenseAndBenefitListHeaders"
                  :items="expensesAndBenefits"
                  item-key="uid"
                  :header-props="{ sortByText: 'ソート' }"
                  hide-default-footer
                  disable-pagination
                  no-data-text="データがありません"
                  group-by="groupName"
                  sort-by="businessDate"
                >
                  <template #top>
                    <div class="w-full d-flex">
                      <v-spacer />
                      <v-btn
                        :ripple="false"
                        color="secondary"
                        small
                        class="ma-2"
                        @click="downloadExpensesAndBenefits"
                      >
                        <v-icon left>
                          {{ icons.mdiFileExcel }}
                        </v-icon>
                        ダウンロード
                      </v-btn>
                    </div>
                  </template>

                  <template #[`group.header`]="{group}">
                    <td colspan="5">
                      {{ group }}
                    </td>
                  </template>

                  <template #[`item.amount`]="{item}">
                    ¥{{ item.amount.toLocaleString() }}
                  </template>

                  <template #[`item.isCash`]="{item}">
                    <v-simple-checkbox
                      :value="item.isCash"
                      :disabled="true"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-expand-transition>
          </v-row>
        </template>

        <div v-else>
          <v-tabs
            v-model="currentDetailListIdx"
            background-color="transparent"
            class="elevation-0"
          >
            <v-tab
              v-for="tab in detailListTabs"
              :key="`tab-for-${tab.text}`"
            >
              {{ tab.text }}
            </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="currentDetailListIdx"
            touchless
            style="background: transparent"
          >
            <v-tab-item
              v-for="tab in detailListTabs"
              :key="`data-table-for-${tab.text}`"
            >
              <v-data-table
                :headers="tab.headers"
                :items="tab.items"
                :header-props="{ sortByText: 'ソート' }"
                class="text-no-wrap"
                hide-default-footer
                disable-pagination
                no-data-text="データがありません"
                fixed-header
              >
                <template #top>
                  <div class="w-full d-flex">
                    <v-spacer />
                    <v-btn
                      :ripple="false"
                      color="secondary"
                      small
                      class="ma-2"
                      @click="downloadDetailList"
                    >
                      <v-icon left>
                        {{ icons.mdiFileExcel }}
                      </v-icon>
                      ダウンロード
                    </v-btn>
                  </div>
                </template>

                <template #[`item.cashAmount`]="{item}">
                  <span v-if="Number(item.cashAmount)">
                    ¥{{ Number(item.cashAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.cardAmount`]="{item}">
                  <span v-if="Number(item.cardAmount)">
                    ¥{{ Number(item.cardAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.balanceReceivableAmount`]="{item}">
                  <span v-if="Number(item.balanceReceivableAmount)">
                    ¥{{ Number(item.balanceReceivableAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.benefitAmount`]="{item}">
                  <span v-if="Number(item.benefitAmount)">
                    ¥{{ Number(item.benefitAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.income`]="{item}">
                  <v-chip
                    color="primary"
                    class="v-chip-light-bg primary--text"
                  >
                    ¥{{ Number(item.income).toLocaleString() }}
                  </v-chip>
                </template>

                <template #[`item.castEarningAmount`]="{item}">
                  <span v-if="Number(item.castEarningAmount)">
                    ¥{{ Number(item.castEarningAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.waiterEarningAmount`]="{item}">
                  <span v-if="Number(item.waiterEarningAmount)">
                    ¥{{ Number(item.waiterEarningAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.allianceEarningAmount`]="{item}">
                  <span v-if="Number(item.allianceEarningAmount)">
                    ¥{{ Number(item.allianceEarningAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.expenseAmount`]="{item}">
                  <span v-if="Number(item.expenseAmount)">
                    ¥{{ Number(item.expenseAmount).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.spending`]="{item}">
                  <v-chip
                    color="secondary"
                    class="v-chip-light-bg secondary--text"
                  >
                    ¥{{ Number(item.spending).toLocaleString() }}
                  </v-chip>
                </template>

                <template #[`item.profit`]="{item}">
                  <v-chip
                    color="success"
                    class="v-chip-light-bg success--text"
                  >
                    ¥{{ Number(item.profit).toLocaleString() }}
                  </v-chip>
                </template>

                <template #[`item.realMoneyProfit`]="{item}">
                  <span v-if="Number(item.realMoneyProfit)">
                    ¥{{ Number(item.realMoneyProfit).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>

                <template #[`item.diff`]="{item}">
                  <span
                    v-if="Number(item.diff)"
                    :class="Number(item.diff) > 0 ? 'success--text' : 'error--text'"
                  >
                    ¥{{ Number(item.diff).toLocaleString() }}
                  </span>
                  <span v-else>-</span>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-8 daily-report-contents-wrapper">
      <v-card-title>
        パフォーマンス
      </v-card-title>

      <v-lazy
        v-model="isPerformanceActive"
        :options="{ threshold: .5 }"
        min-height="300"
        min-width="100%"
        transition="fade-transition"
      >
        <v-card-text>
          <app-card-actions
            class="daily-report-contents-wrapper mb-6"
            action-collapse
          >
            <template #title>
              顧客
            </template>
            <v-card-text class="mb-4">
              <v-skeleton-loader
                v-if="isLoadingPerformanceContent"
                type="table"
              />
              <customer-counts
                v-else
                :date="date"
                :sales-amount="salesAmount"
                :customer-count="performance.customerCount"
                :customer-group-count="performance.customerGroupCount"
                :unguided-customer-count="performance.unguidedCustomerCount"
                :operation-rate="performance.operationRate"
                :hourly-customer-count-summaries="hourlyCustomerCountSummaries"
                :table-counts-by-check-amount-range="tableCountsByCheckAmountRange"
                :readonly="interval > 0 || !!endDate"
              />
            </v-card-text>
            <v-card-text>
              <v-skeleton-loader
                v-if="isLoadingPerformanceContent"
                type="table"
              />
              <table-result-overviews
                v-else
                :items="tableResultOverviews"
                :dynamic-headers="dynamicTableResultOverviewHeaders"
                :sales-per-customer-tag="performance.salesPerCustomerTag"
                :date="date"
                :interval="interval"
              />
            </v-card-text>

            <v-card-text class="d-flex">
              <v-spacer />
              <v-btn
                :ripple="false"
                color="accent"
                @click="show.customerPerformanceAnalytics = !show.customerPerformanceAnalytics"
              >
                <v-icon left>
                  {{ icons.mdiGoogleAnalytics }}
                </v-icon>
                データ分析
              </v-btn>
            </v-card-text>

            <v-expand-transition>
              <v-card-text v-show="show.customerPerformanceAnalytics">
                <hourly-customer-count-summaries-analytics
                  :key="JSON.stringify(hourlyCustomerCountSummaries)"
                  :hourly-customer-count-summaries="hourlyCustomerCountSummaries"
                />

                <sales-per-customer-tag-analytics
                  :key="JSON.stringify(performance.salesPerCustomerTag)"
                  :sales-per-customer-tag="performance.salesPerCustomerTag"
                />
              </v-card-text>
            </v-expand-transition>
          </app-card-actions>

          <app-card-actions
            class="daily-report-contents-wrapper mb-6"
            action-collapse
          >
            <template #title>
              プレイヤー
            </template>

            <v-card-text>
              <v-skeleton-loader
                v-if="isLoadingPerformanceContent"
                type="table"
              />
              <Management
                v-else
                :show-date-picker="false"
              />
            </v-card-text>
          </app-card-actions>
        </v-card-text>
      </v-lazy>
    </v-card>

    <v-card class="mb-8 daily-report-contents-wrapper">
      <v-card-title class="pb-0">
        データ
      </v-card-title>

      <v-card-text>
        <v-lazy
          v-model="isDataActive"
          :options="{ threshold: .5 }"
          min-height="200"
          min-width="100%"
          transition="fade-transition"
        >
          <v-card class="daily-report-contents-wrapper">
            <v-card-text class="pt-0">
              <v-row class="mt-4 todays-data">
                <v-col cols="12">
                  <Data :show-date-picker="false" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-lazy>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  watch,
} from '@vue/composition-api'
import {
  mdiFileExcel,
  mdiSigma,
  mdiSlashForwardBox,
  mdiCogOutline,
  mdiClose,
  mdiGoogleAnalytics,
  mdiFormatListNumbered,
} from '@mdi/js'
import {
  chain,
  map,
  each,
  sumBy,
} from 'lodash'
import {
  startOfMonth,
  format,
  eachDayOfInterval,
  getDay,
} from 'date-fns'
import * as XLSX from 'xlsx/xlsx.mjs'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { BusinessResultDetailListHeaders, BusinessResultMoneyDetailListHeaders, ExpenseAndBenefitListHeaders } from '@/utils/constants/table-headers'
import SalesApi from '@/api/admin/Sales'
import FinanceApi from '@/api/admin/Finance'
import BenefitApi from '@/api/admin/Benefit'
import ExpenseApi from '@/api/admin/Expense'
import LaborCostApi from '@/api/admin/LaborCost'
import TableSummaryApi from '@/api/admin/TableSummary'
import RealMoneyProfitApi from '@/api/admin/RealMoneyProfit'
import RegularDayOffApi from '@/api/admin/RegularDayOff'
import useCompInit from '@/views/composable/useCompInit'
import useBusinessResultDetailListDataTable from '@/views/composable/data-table/useBusinessResultDetailListDataTable'
import useBusinessResultMoneyDetailListDataTable from '@/views/composable/data-table/useBusinessResultMoneyDetailListDataTable'
import useExpensesAndBenefitsDataTable from '@/views/composable/data-table/useExpensesAndBenefitsDataTable'
import Management from '@/views/admin/hr/Management.vue'
import Data from '@/views/waiter/Data.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import HourlyCustomerCountSummariesAnalytics from '@/views/components/daily_report/Analytics/HourlyCustomerCountSummariesAnalytics.vue'
import SalesPerCustomerTagAnalytics from '@/views/components/daily_report/Analytics/SalesPerCustomerTagAnalytics.vue'
import TableResultOverviews from './TableResultOverviews.vue'
import CustomerCounts from './CustomerCounts.vue'
import MoneyDetail from './MoneyDetail.vue'
import Detail from './Detail.vue'

export default {
  components: {
    AppCardActions,
    Detail,
    MoneyDetail,
    CustomerCounts,
    TableResultOverviews,
    Management,
    Data,
    YonaEditDialog,
    HourlyCustomerCountSummariesAnalytics,
    SalesPerCustomerTagAnalytics,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
    fixedAt: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup(props) {
    const { isLoading, initWith } = useCompInit()
    const isLoadingDetailContent = ref(false)
    const isLoadingPerformanceContent = ref(false)
    const isDataActive = ref(false)
    const isPerformanceActive = ref(false)
    const isDownloading = ref(false)
    const incomeDetail = reactive({
      cashAmount: 0,
      cardAmount: 0,
      balanceReceivableAmount: 0,
      benefitAmount: 0,
    })
    const moneyIncomeDetail = reactive({
      cashAmount: 0,
      balanceReceivableAmount: 0,
      benefitAmount: 0,
    })
    const spendingDetail = reactive({
      castEarningAmount: 0,
      waiterEarningAmount: 0,
      allianceEarningAmount: 0,
      expenseAmount: 0,
    })
    const moneySpendingDetail = reactive({
      castEarningAmount: 0,
      waiterEarningAmount: 0,
      allianceEarningAmount: 0,
      expenseAmount: 0,
    })
    const benefits = ref([])
    const expenses = ref([])
    const laborCosts = ref([])
    const performance = reactive({
      customerCount: 0,
      customerGroupCount: 0,
      unguidedCustomerCount: 0,
      operationRate: 0,
      salesPerCast: {},
      salesPerCustomerTag: {},
      salesPerAlliance: {},
    })
    const incomeDetailList = ref([])
    const moneyIncomeDetailList = ref([])
    const spendingDetailList = ref([])
    const moneySpendingDetailList = ref([])
    const realMoneyProfits = ref([])
    const currentDataTabIdx = ref(0)
    const onDetailList = ref(false)
    const currentDetailListIdx = ref(0)
    const hourlyCustomerCountSummaries = ref([])
    const tableCountsByCheckAmountRange = ref([])
    const tableResultOverviews = ref([])
    const dynamicTableResultOverviewHeaders = ref([])
    const dateToAccumulateFrom = ref(format(startOfMonth(new Date(props.date)), 'yyyy-MM-dd'))
    const isLoadingAccContent = ref(false)
    const acc = reactive({
      income: 0,
      spending: 0,
      moneyIncome: 0,
      moneySpending: 0,
    })
    const daysToAverage = ref(new Date(props.date).getDate())
    const regularDayOffs = ref([])
    const selectedDaysOfWeek = ref([])
    const show = reactive({
      expensesAndBenefits: false,
      customerPerformanceAnalytics: false,
    })

    const { items: expensesAndBenefits, buildXlsxData: buildXlsxDataExpensesAndBenefits } = useExpensesAndBenefitsDataTable({ expenses, benefits })
    const { items: detailList, buildXlsxData: buildDetailListXlsxData } = useBusinessResultDetailListDataTable({ incomeDetailList, spendingDetailList })
    const { items: moneyDetailList, buildXlsxData: buildMoneyDetailListXlsxData } = useBusinessResultMoneyDetailListDataTable({ moneyIncomeDetailList, moneySpendingDetailList, realMoneyProfits })

    // NOTE: 営業売上
    const salesAmount = computed(() => incomeDetail.cashAmount + incomeDetail.cardAmount + incomeDetail.balanceReceivableAmount)
    // NOTE: 営業売上(現金)
    const moneySalesAmount = computed(() => moneyIncomeDetail.cashAmount + moneyIncomeDetail.balanceReceivableAmount)
    // NOTE: 給料
    const laborCostAmount = computed(() => spendingDetail.castEarningAmount + spendingDetail.waiterEarningAmount + spendingDetail.allianceEarningAmount)
    // NOTE: 日払
    const moneyLaborCostAmount = computed(() => moneySpendingDetail.castEarningAmount + moneySpendingDetail.waiterEarningAmount + moneySpendingDetail.allianceEarningAmount)

    const finance = computed(() => {
      const income = salesAmount.value + incomeDetail.benefitAmount
      const spending = laborCostAmount.value + spendingDetail.expenseAmount

      return {
        profit: income - spending,
        income,
        spending,
      }
    })

    const moneyFinance = computed(() => {
      const income = moneySalesAmount.value + moneyIncomeDetail.benefitAmount
      const spending = moneyLaborCostAmount.value + moneySpendingDetail.expenseAmount

      return {
        profit: income - spending,
        income,
        spending,
      }
    })

    const moneyProfitDiff = computed(() => {
      return sumBy(moneyDetailList.value, 'diff')
    })

    const detailListTabs = computed(() => {
      return [
        { items: detailList.value, headers: BusinessResultDetailListHeaders, text: '発生' },
        { items: moneyDetailList.value, headers: BusinessResultMoneyDetailListHeaders, text: '現金' },
      ]
    })

    const isDailyReport = computed(() => {
      return props.interval === 0 && !props.endDate
    })

    const getRegularDayOffs = async () => {
      const res = await RegularDayOffApi.getRegularDayOffs()

      if (res?.data) {
        // NOTE: saveAllRegularDayOffsと処理同じ
        regularDayOffs.value = [...res.data.regularDayOffs.data]
        selectedDaysOfWeek.value = map(res.data.regularDayOffs.data, 'attributes.dayOfWeek')

        const targetDates = eachDayOfInterval({
          start: startOfMonth(new Date(props.date)),
          end: new Date(props.date),
        })

        // NOTE: 月初から今日までの日付のうち、定休日を除外
        daysToAverage.value = chain(targetDates).reject(date => selectedDaysOfWeek.value.includes(getDay(date))).value().length
      }
    }

    const resetRegularDayOffsForm = () => {
      selectedDaysOfWeek.value = map(regularDayOffs.value, 'attributes.dayOfWeek')
    }

    const saveAllRegularDayOffs = async () => {
      const res = await RegularDayOffApi.saveAllRegularDayOffs({
        daysOfWeek: selectedDaysOfWeek.value,
      })

      if (res?.data) {
        // NOTE: getRegularDayOffsと処理同じ
        regularDayOffs.value = [...res.data.regularDayOffs.data]
        selectedDaysOfWeek.value = map(res.data.regularDayOffs.data, 'attributes.dayOfWeek')

        const targetDates = eachDayOfInterval({
          start: startOfMonth(new Date(props.date)),
          end: new Date(props.date),
        })

        // NOTE: 月初から今日までの日付のうち、定休日を除外
        daysToAverage.value = chain(targetDates).reject(date => selectedDaysOfWeek.value.includes(getDay(date))).value().length
      }
    }

    // NOTE: 発生 > 収入
    const getIncomeDetail = async () => {
      const res = await FinanceApi.getIncomeDetail({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        incomeDetail.cashAmount = Number(res.data.cashAmount)
        incomeDetail.cardAmount = Number(res.data.cardAmount)
        incomeDetail.balanceReceivableAmount = Number(res.data.balanceReceivableAmount)
        incomeDetail.benefitAmount = Number(res.data.benefitAmount)
      }
    }

    // NOTE: 現金 > 収入
    const getMoneyIncomeDetail = async () => {
      const res = await FinanceApi.getMoneyIncomeDetail({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        moneyIncomeDetail.cashAmount = Number(res.data.cashAmount)
        moneyIncomeDetail.balanceReceivableAmount = Number(res.data.balanceReceivableAmount)
        moneyIncomeDetail.benefitAmount = Number(res.data.benefitAmount)
      }
    }

    // NOTE: 発生 > 支出
    const getSpendingDetail = async () => {
      const res = await FinanceApi.getSpendingDetail({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        spendingDetail.castEarningAmount = Number(res.data.castEarningAmount)
        spendingDetail.waiterEarningAmount = Number(res.data.waiterEarningAmount)
        spendingDetail.allianceEarningAmount = Number(res.data.allianceEarningAmount)
        spendingDetail.expenseAmount = Number(res.data.expenseAmount)
      }
    }

    // NOTE: 現金 > 支出
    const getMoneySpendingDetail = async () => {
      const res = await FinanceApi.getMoneySpendingDetail({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        moneySpendingDetail.castEarningAmount = Number(res.data.castEarningAmount)
        moneySpendingDetail.waiterEarningAmount = Number(res.data.waiterEarningAmount)
        moneySpendingDetail.allianceEarningAmount = Number(res.data.allianceEarningAmount)
        moneySpendingDetail.expenseAmount = Number(res.data.expenseAmount)
      }
    }

    // NOTE: 諸入
    const getBenefits = async () => {
      const res = await BenefitApi.getBenefits({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        benefits.value = res.data.benefits.data
      }
    }

    // NOTE: 諸費
    const getExpenses = async () => {
      const res = await ExpenseApi.getExpenses({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        expenses.value = res.data.expenses.data
      }
    }

    // NOTE: 給料
    const getLaborCosts = async () => {
      const res = await LaborCostApi.getLaborCosts({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        laborCosts.value = res.data.laborCosts
      }
    }

    // NOTE: 収支 > 日次(発生収入)
    const getIncomeDetailList = async () => {
      const res = await FinanceApi.getIncomeDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        incomeDetailList.value = res.data.incomeDetailList
      }
    }
    // NOTE: 収支 > 日次(現金収入)
    const getMoneyIncomeDetailList = async () => {
      const res = await FinanceApi.getMoneyIncomeDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        moneyIncomeDetailList.value = res.data.moneyIncomeDetailList
      }
    }

    // NOTE: 収支 > 日次(発生支出)
    const getSpendingDetailList = async () => {
      const res = await FinanceApi.getSpendingDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        spendingDetailList.value = res.data.spendingDetailList
      }
    }

    // NOTE: 収支 > 日次(現金支出)
    const getMoneySpendingDetailList = async () => {
      const res = await FinanceApi.getMoneySpendingDetailList({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
        isDailyListBetweenIntervalRange: true,
      })

      if (res?.status === 200) {
        moneySpendingDetailList.value = res.data.moneySpendingDetailList
      }
    }

    // NOTE: パフォーマンス
    const getPerformance = async () => {
      const res = await SalesApi.getPerformance({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        performance.customerCount = Number(res.data.performance.customerCount)
        performance.customerGroupCount = Number(res.data.performance.customerGroupCount)
        performance.unguidedCustomerCount = Number(res.data.performance.unguidedCustomerCount)
        performance.operationRate = Number(res.data.performance.operationRate)
        performance.salesPerCustomerTag = res.data.performance.salesPerCustomerTag
        performance.salesPerCast = res.data.performance.salesPerCast
        performance.salesPerAlliance = res.data.performance.salesPerAlliance
      }
    }

    // NOTE: 客数集計
    const getHourlyCustomerCounts = async () => {
      const res = await TableSummaryApi.getHourlyCustomerCounts({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        hourlyCustomerCountSummaries.value = res.data.hourlyCustomerCountSummaries
      }
    }

    // NOTE: 単価集計
    const getTableCountsByCheckAmountRange = async () => {
      const res = await TableSummaryApi.getTableCountsByCheckAmountRange({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        tableCountsByCheckAmountRange.value = res.data.tableCountsByCheckAmountRange
      }
    }

    // NOTE: 卓サマリ
    const getTableResultOverviews = async () => {
      const res = await TableSummaryApi.getOverviews({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.status === 200) {
        tableResultOverviews.value = res.data.overviews
        dynamicTableResultOverviewHeaders.value = res.data.dynamicHeaders
      }
    }

    // NOTE: 現金利益(実態)
    const getRealMoneyProfits = async () => {
      const res = await RealMoneyProfitApi.getRealMoneyProfits({
        date: props.date,
        interval: props.interval,
        endDate: props.endDate,
      })

      if (res?.data) {
        realMoneyProfits.value = res.data.realMoneyProfits.data
      }
    }

    // NOTE: 累計
    const getAccIncome = async () => {
      const res = await FinanceApi.getIncomeDetail({ date: dateToAccumulateFrom.value, endDate: props.date })
      if (res?.data) acc.income = Number(res.data.cashAmount) + Number(res.data.cardAmount) + Number(res.data.balanceReceivableAmount) + Number(res.data.benefitAmount)
    }
    const getAccMoneyIncome = async () => {
      const res = await FinanceApi.getMoneyIncomeDetail({ date: dateToAccumulateFrom.value, endDate: props.date })

      if (res?.data) acc.moneyIncome = Number(res.data.cashAmount) + Number(res.data.balanceReceivableAmount) + Number(res.data.benefitAmount)
    }
    const getAccSpending = async () => {
      const res = await FinanceApi.getSpendingDetail({ date: dateToAccumulateFrom.value, endDate: props.date })

      if (res?.data) acc.spending = Number(res.data.castEarningAmount) + Number(res.data.waiterEarningAmount) + Number(res.data.allianceEarningAmount) + Number(res.data.expenseAmount)
    }
    const getAccMoneySpending = async () => {
      const res = await FinanceApi.getMoneySpendingDetail({ date: dateToAccumulateFrom.value, endDate: props.date })

      if (res?.data) acc.moneySpending = Number(res.data.castEarningAmount) + Number(res.data.waiterEarningAmount) + Number(res.data.allianceEarningAmount) + Number(res.data.expenseAmount)
    }
    const getAcc = () => {
      initWith(
        [
          getAccIncome(),
          getAccMoneyIncome(),
          getAccSpending(),
          getAccMoneySpending(),
        ],
        isLoadingAccContent,
      )
    }

    // NOTE: 諸入が更新されたときに使ってる
    const getIncome = () => {
      initWith(
        [
          getIncomeDetail(),
          getMoneyIncomeDetail(),
          getBenefits(),

          getRealMoneyProfits(),
          getIncomeDetailList(),
          getMoneyIncomeDetailList(),
          getSpendingDetailList(),
          getMoneySpendingDetailList(),
          getRealMoneyProfits(),

          getAccIncome(),
          getAccMoneyIncome(),
        ],
        isLoadingDetailContent,
      )
    }
    // NOTE: 諸費が更新されたときに使ってる
    const getSpending = () => {
      initWith(
        [
          getSpendingDetail(),
          getMoneySpendingDetail(),
          getExpenses(),
          getLaborCosts(),

          getRealMoneyProfits(),
          getIncomeDetailList(),
          getMoneyIncomeDetailList(),
          getSpendingDetailList(),
          getMoneySpendingDetailList(),
          getRealMoneyProfits(),

          getAccSpending(),
          getAccMoneySpending(),
        ],
        isLoadingDetailContent,
      )
    }

    const downloadDetailList = () => {
      isDownloading.value = true

      const detailListExcel = buildDetailListXlsxData()
      const moneyDetailListExcel = buildMoneyDetailListXlsxData()

      const sheets = [
        { name: '収支(発生)', data: detailListExcel },
        { name: '収支(現金)', data: moneyDetailListExcel },
      ]

      const wb = XLSX.utils.book_new()
      each(sheets, sheet => {
        const data = XLSX.utils.json_to_sheet(sheet.data)
        XLSX.utils.book_append_sheet(wb, data, sheet.name)
      })

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`

      XLSX.writeFile(wb, `収支_${title}.xlsx`)

      isDownloading.value = false
    }

    const downloadExpensesAndBenefits = () => {
      isDownloading.value = true

      const expensesAndBenefitsExcel = buildXlsxDataExpensesAndBenefits()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(expensesAndBenefitsExcel)
      XLSX.utils.book_append_sheet(wb, data, '諸入費一覧')

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `諸入費_${title}.xlsx`)
      isDownloading.value = false
    }

    watch(() => isPerformanceActive.value, (newOn, prevOn) => {
      if (!prevOn && newOn) {
        initWith(
          [
            getPerformance(),
            getHourlyCustomerCounts(),
            getTableCountsByCheckAmountRange(),
            getTableResultOverviews(),
          ],
          isLoadingPerformanceContent,
        )
      }
    })

    watch(() => dateToAccumulateFrom.value, () => {
      getAcc()
    })

    initWith([
      getIncomeDetail(),
      getMoneyIncomeDetail(),
      getSpendingDetail(),
      getMoneySpendingDetail(),
      getBenefits(),
      getExpenses(),
      getLaborCosts(),
      getIncomeDetailList(),
      getMoneyIncomeDetailList(),
      getSpendingDetailList(),
      getMoneySpendingDetailList(),
      getRealMoneyProfits(),

      getAcc(),

      getRegularDayOffs(),
    ])

    return {
      // data
      isLoading,
      isLoadingDetailContent,
      isLoadingPerformanceContent,
      isPerformanceActive,
      isDataActive,
      incomeDetail,
      moneyIncomeDetail,
      spendingDetail,
      moneySpendingDetail,
      benefits,
      expenses,
      laborCosts,
      performance,
      currentDataTabIdx,
      onDetailList,
      incomeDetailList,
      moneyIncomeDetailList,
      spendingDetailList,
      moneySpendingDetailList,
      moneyProfitDiff,
      realMoneyProfits,
      currentDetailListIdx,
      hourlyCustomerCountSummaries,
      tableCountsByCheckAmountRange,
      tableResultOverviews,
      dynamicTableResultOverviewHeaders,
      dateToAccumulateFrom,
      isLoadingAccContent,
      acc,
      daysToAverage,
      regularDayOffs,
      selectedDaysOfWeek,
      isPickingDate: ref(false),
      daysOfWeek: [{ value: 1, label: '月' }, { value: 2, label: '火' }, { value: 3, label: '水' }, { value: 4, label: '木' }, { value: 5, label: '金' }, { value: 6, label: '土' }, { value: 0, label: '日' }],
      ExpenseAndBenefitListHeaders,
      show,

      // computed
      salesAmount,
      moneySalesAmount,
      laborCostAmount,
      moneyLaborCostAmount,
      finance,
      moneyFinance,
      detailList,
      moneyDetailList,
      detailListTabs,
      isDailyReport,
      expensesAndBenefits,

      // methods
      getIncome,
      getSpending,
      downloadDetailList,
      resetRegularDayOffsForm,
      saveAllRegularDayOffs,
      downloadExpensesAndBenefits,

      icons: {
        mdiFileExcel,
        mdiSigma,
        mdiSlashForwardBox,
        mdiCogOutline,
        mdiClose,
        mdiGoogleAnalytics,
        mdiFormatListNumbered,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
.disable-actions {
  opacity: .2;
  pointer-events: none;
}
</style>
