<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="py-0">
            <v-list
              two-line
              subheader
            >
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg success--text justify-center d-flex flex-column"
                  size="60"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="success"
                  >
                    {{ icons.mdiTrendingUp }}
                  </v-icon>
                  利益
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <!-- TODO: 不明金が+のときはでてきていない？ -->
                    <div class="d-flex flex-wrap">
                      <div>
                        <span class="font-weight-semibold text-xl">
                          ¥{{ profit.toLocaleString() }}
                        </span>
                        <small>
                          {{ `(利益率: ${profitPercent}%)` }}
                        </small>
                      </div>

                      <v-spacer />

                      <div
                        v-if="moneyProfitDiff > 0 || moneyProfitDiff < 0"
                        sm="6"
                        class="text-base d-flex align-center"
                      >
                        <span
                          class="font-weight-semibold"
                          :class="moneyProfitDiff > 0 ? 'success--text' : 'error--text'"
                        >
                          {{ `¥${moneyProfitDiff.toLocaleString()}` }}
                        </span>
                        <span class="secondary--text text-xs ml-1">
                          (不明金)
                        </span>
                      </div>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row
                      v-if="isDailyReport"
                      no-gutters
                      class="pt-1"
                    >
                      <v-col>
                        <span class="mr-2">
                          累計:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${(accumulatedIncome - accumulatedSpending).toLocaleString()}` }}
                        </span>
                      </v-col>
                      <v-col>
                        <span class="mr-2">
                          平均:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${Math.round((accumulatedIncome - accumulatedSpending) / daysToAverage).toLocaleString()}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="pt-0">
            <v-list
              two-line
              subheader
            >
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg primary--text justify-center d-flex flex-column"
                  size="60"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="primary"
                  >
                    {{ icons.mdiCurrencyJpy }}
                  </v-icon>
                  収入
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold text-xl">
                    {{ `¥${income.toLocaleString()}` }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <v-row
                      v-if="isDailyReport"
                      no-gutters
                      class="pt-1"
                    >
                      <v-col>
                        <span class="mr-2">
                          累計:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${accumulatedIncome.toLocaleString()}` }}
                        </span>
                      </v-col>
                      <v-col>
                        <span class="mr-2">
                          平均:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${Math.round(accumulatedIncome / daysToAverage).toLocaleString()}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-treeview :items="moneyIncomeTreeviewItems">
                <template #label="{ item: node }">
                  <v-row>
                    <v-col
                      cols="6"
                      class="white-space-normal"
                    >
                      {{ node.name }}
                    </v-col>
                    <v-col
                      cols="6"
                      align-end
                      class="white-space-normal"
                    >
                      {{ `¥${node.amount.toLocaleString()}` }}
                    </v-col>
                  </v-row>
                </template>
              </v-treeview>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="pt-0">
            <v-list
              two-line
              subheader
            >
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg secondary--text justify-center d-flex flex-column"
                  size="60"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="secondary"
                  >
                    {{ icons.mdiChartBar }}
                  </v-icon>
                  支出
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-semibold text-xl">
                      ¥{{ spending.toLocaleString() }}
                    </span>
                    <small>
                      {{ `(比率: ${spendingPercent}%)` }}
                    </small>
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <v-row
                      v-if="isDailyReport"
                      no-gutters
                      class="pt-1"
                    >
                      <v-col>
                        <span class="mr-2">
                          累計:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${accumulatedSpending.toLocaleString()}` }}
                        </span>
                      </v-col>
                      <v-col>
                        <span class="mr-2">
                          平均:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${Math.round(accumulatedSpending / daysToAverage).toLocaleString()}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-treeview :items="moneySpendingTreeviewItems">
                <template #label="{ item: node }">
                  <v-row>
                    <v-col
                      cols="6"
                      class="white-space-normal"
                    >
                      {{ node.name }}
                    </v-col>
                    <v-col
                      cols="6"
                      align-end
                      class="white-space-normal"
                    >
                      {{ `¥${node.amount.toLocaleString()}` }}
                    </v-col>
                  </v-row>
                </template>
              </v-treeview>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { compact } from 'lodash'
import {
  mdiTrendingUp,
  mdiCurrencyJpy,
  mdiChartBar,
  mdiCalendar,
} from '@mdi/js'

export default {
  props: {
    profit: {
      type: Number,
      required: true,
      default: 0,
    },
    moneyProfitDiff: {
      type: Number,
      default: 0,
    },
    income: {
      type: Number,
      required: true,
      default: 0,
    },
    spending: {
      type: Number,
      required: true,
      default: 0,
    },
    salesAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    cashAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    balanceReceivableAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    benefitAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    laborCostAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    castEarningAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    waiterEarningAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    allianceEarningAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    expenseAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    accumulatedIncome: {
      type: Number,
      default: 0,
    },
    accumulatedSpending: {
      type: Number,
      default: 0,
    },
    daysToAverage: {
      type: Number,
      default: 0,
    },
    isDailyReport: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const moneyIncomeTreeviewItems = computed(() => {
      return [
        {
          id: '現金売上',
          name: '売上',
          amount: props.salesAmount,
          children: [
            {
              id: '現金',
              name: '現金',
              amount: props.cashAmount,
            },
            {
              id: '売掛金回収',
              name: '売掛金回収',
              amount: props.balanceReceivableAmount,
            },
          ],
        },
        {
          id: '現金諸入',
          name: '現金諸入',
          amount: props.benefitAmount,
        },
      ]
    })

    const moneySpendingTreeviewItems = computed(() => {
      return [
        {
          id: '日払',
          name: '日払',
          amount: props.laborCostAmount,
          children: compact([
            props.castEarningAmount > 0
              ? {
                id: 'キャスト',
                name: 'キャスト',
                amount: props.castEarningAmount,
              }
              : null,
            props.waiterEarningAmount > 0
              ? {
                id: 'ボーイ',
                name: 'ボーイ',
                amount: props.waiterEarningAmount,
              }
              : null,
            props.allianceEarningAmount > 0
              ? {
                id: 'アライアンス',
                name: 'アライアンス',
                amount: props.allianceEarningAmount,
              }
              : null,
          ]),
        },
        {
          id: '現金諸費',
          name: '現金諸費',
          amount: props.expenseAmount,
        },
      ]
    })

    const profitPercent = computed(() => {
      if (props.income === 0) return 0

      return Math.round((props.profit / props.income) * 100)
    })

    const spendingPercent = computed(() => {
      if (props.income === 0) return 0

      return Math.round((props.spending / props.income) * 100)
    })

    return {
      // computed
      moneyIncomeTreeviewItems,
      moneySpendingTreeviewItems,
      profitPercent,
      spendingPercent,

      icons: {
        mdiTrendingUp,
        mdiCurrencyJpy,
        mdiChartBar,
        mdiCalendar,
      },
    }
  },
}
</script>
