<template>
  <div>
    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <v-card>
          <v-card-subtitle>
            時間 / 売上
          </v-card-subtitle>
          <v-card-text>
            <ChartjsComponentLineChart
              :height="500"
              :data="data.timeSales"
              :options="options"
              style="min-height: 500px"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="6"
        cols="12"
      >
        <v-card>
          <v-card-subtitle>
            時間 / 平均単価
          </v-card-subtitle>
          <v-card-text>
            <ChartjsComponentLineChart
              :height="500"
              :data="data.timeAvgSales"
              :options="options"
              style="min-height: 500px"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-subtitle>
            時間 / 客数
          </v-card-subtitle>
          <v-card-text>
            <ChartjsComponentLineChart
              :height="500"
              :data="data.customerCounts"
              :options="options"
              style="min-height: 500px"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import { computed } from '@vue/composition-api'
import { chain } from 'lodash'
import useColor from '@/views/composable/useColor'
import ChartjsComponentLineChart from '@/views/components/charts/ChartjsComponentLineChart.vue'

export default {
  components: {
    ChartjsComponentLineChart,
  },
  props: {
    hourlyCustomerCountSummaries: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  setup(props) {
    const $themeColors = themeConfig.themes.light
    const { chartColors } = useColor()

    const chartProps = computed(() => {
      const times = chain(props.hourlyCustomerCountSummaries)
        .map('beginning')
        .map(timeStr => {
          if (/\d\d:\d\d:\d\d/.test(timeStr)) {
            return timeStr.slice(0, 5)
          }

          return timeStr
        })
        .value()
      const sales = chain(props.hourlyCustomerCountSummaries).map('totalCheckAmount').value()
      const customerAvgSales = chain(props.hourlyCustomerCountSummaries).map('customerAvgCheckAmount').value()
      const customerGroupAvgSales = chain(props.hourlyCustomerCountSummaries).map('customerGroupAvgCheckAmount').value()
      const customerCounts = chain(props.hourlyCustomerCountSummaries).map('customerCount').value()
      const customerGroupCounts = chain(props.hourlyCustomerCountSummaries).map('customerGroupCount').value()
      const workingCastCounts = chain(props.hourlyCustomerCountSummaries).map('workingCastCount').value()

      return {
        times,
        sales,
        customerAvgSales,
        customerGroupAvgSales,
        customerCounts,
        customerGroupCounts,
        workingCastCounts,
      }
    })

    const data = computed(() => {
      const generateDataset = (label, targetData, color) => {
        return {
          label,
          data: targetData,
          borderColor: color,
          lineTension: 0.1,
          pointStyle: 'circle',
          backgroundColor: color,
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: color,
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        }
      }

      return {
        timeSales: {
          labels: chartProps.value.times,
          datasets: [
            generateDataset('売上', chartProps.value.sales, chartColors.primaryColorShade),
          ],
        },
        timeAvgSales: {
          labels: chartProps.value.times,
          datasets: [
            generateDataset('平均客単価', chartProps.value.customerAvgSales, $themeColors.warning),
            generateDataset('平均組単価', chartProps.value.customerGroupAvgSales, '#d4e157'),
          ],
        },
        customerCounts: {
          labels: chartProps.value.times,
          datasets: [
            generateDataset('客数', chartProps.value.customerCounts, chartColors.successColorShade),
            generateDataset('組数', chartProps.value.customerGroupCounts, chartColors.infoColorShade),
            generateDataset('待機キャスト数', chartProps.value.workingCastCounts, chartColors.lineChartDanger),
          ],
        },
      }
    })

    const options = computed(() => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      }
    })

    return {
      chartProps,
      data,
      options,
    }
  },
}
</script>
