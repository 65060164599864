<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="py-0">
            <v-list
              two-line
              subheader
            >
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg success--text justify-center d-flex flex-column"
                  size="60"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="success"
                  >
                    {{ icons.mdiTrendingUp }}
                  </v-icon>
                  利益
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-semibold text-xl">
                      ¥{{ profit.toLocaleString() }}
                    </span>
                    <small>
                      {{ `(利益率: ${profitPercent}%)` }}
                    </small>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row
                      v-if="isDailyReport"
                      no-gutters
                      class="pt-1"
                    >
                      <v-col>
                        <span class="mr-2">
                          累計:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${(accumulatedIncome - accumulatedSpending).toLocaleString()}` }}
                        </span>
                      </v-col>
                      <v-col>
                        <span class="mr-2">
                          平均:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${Math.round((accumulatedIncome - accumulatedSpending) / daysToAverage).toLocaleString()}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="pt-0">
            <v-list
              two-line
              subheader
            >
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg primary--text justify-center d-flex flex-column"
                  size="60"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="primary"
                  >
                    {{ icons.mdiCurrencyJpy }}
                  </v-icon>
                  収入
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold text-xl">
                    {{ `¥${income.toLocaleString()}` }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <v-row
                      v-if="isDailyReport"
                      no-gutters
                      class="pt-1"
                    >
                      <v-col>
                        <span class="mr-2">
                          累計:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${accumulatedIncome.toLocaleString()}` }}
                        </span>
                      </v-col>
                      <v-col>
                        <span class="mr-2">
                          平均:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${Math.round(accumulatedIncome / daysToAverage).toLocaleString()}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <income-treeview
                :sales-amount="salesAmount"
                :cash-amount="cashAmount"
                :card-amount="cardAmount"
                :balance-receivable-amount="balanceReceivableAmount"
                :benefits="benefits"
                :date="date"
                :benefit-on="fixedAt"
                @updated="$emit('income-updated')"
              />
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="pt-0">
            <v-list
              two-line
              subheader
            >
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="v-avatar-light-bg secondary--text justify-center d-flex flex-column"
                  size="60"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="secondary"
                  >
                    {{ icons.mdiChartBar }}
                  </v-icon>
                  支出
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-semibold text-xl">
                      ¥{{ spending.toLocaleString() }}
                    </span>
                    <small>
                      {{ `(比率: ${spendingPercent}%)` }}
                    </small>
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <v-row
                      v-if="isDailyReport"
                      no-gutters
                      class="pt-1"
                    >
                      <v-col>
                        <span class="mr-2">
                          累計:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${accumulatedSpending.toLocaleString()}` }}
                        </span>
                      </v-col>
                      <v-col>
                        <span class="mr-2">
                          平均:
                        </span>
                        <span class="font-weight-semibold">
                          {{ `¥${Math.round(accumulatedSpending / daysToAverage).toLocaleString()}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <spending-treeview
                :labor-costs="laborCosts"
                :expenses="expenses"
                :date="date"
                :expense-on="fixedAt"
                @updated="$emit('spending-updated')"
              />
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  mdiTrendingUp,
  mdiCurrencyJpy,
  mdiChartBar,
  mdiCalendar,
} from '@mdi/js'
import IncomeTreeview from './IncomeTreeview.vue'
import SpendingTreeview from './SpendingTreeview.vue'

export default {
  components: {
    IncomeTreeview,
    SpendingTreeview,
  },
  props: {
    profit: {
      type: Number,
      required: true,
      default: 0,
    },
    income: {
      type: Number,
      required: true,
      default: 0,
    },
    spending: {
      type: Number,
      required: true,
      default: 0,
    },
    salesAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    cashAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    cardAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    balanceReceivableAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    benefits: {
      type: Array,
      required: true,
      default: () => [],
    },
    laborCosts: {
      type: Array,
      required: true,
      default: () => [],
    },
    expenses: {
      type: Array,
      required: true,
      default: () => [],
    },
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    fixedAt: {
      type: String,
      required: true,
      default: () => new Date().toISOString('ja-JP'),
    },
    accumulatedIncome: {
      type: Number,
      default: 0,
    },
    accumulatedSpending: {
      type: Number,
      default: 0,
    },
    daysToAverage: {
      type: Number,
      default: 0,
    },
    isDailyReport: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const profitPercent = computed(() => {
      if (props.income === 0) return 0

      return Math.round((props.profit / props.income) * 100)
    })

    const spendingPercent = computed(() => {
      if (props.income === 0) return 0

      return Math.round((props.spending / props.income) * 100)
    })

    return {
      // computed
      profitPercent,
      spendingPercent,

      icons: {
        mdiTrendingUp,
        mdiCurrencyJpy,
        mdiChartBar,
        mdiCalendar,
      },
    }
  },
}
</script>
