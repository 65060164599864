<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <v-row>
      <v-col
        md="3"
        cols="12"
      >
        <v-card>
          <v-card-text>
            <div class="d-flex align-center">
              <v-avatar
                class="v-avatar-light-bg accent--text justify-center mr-4"
                size="40"
                rounded
              >
                <v-icon
                  size="30"
                  color="accent"
                >
                  {{ icons.mdiAccountGroupOutline }}
                </v-icon>
              </v-avatar>

              <div class="w-full">
                <v-row no-gutters class="d-flex align-end">
                  <v-col cols="6" class="font-weight-semibold text-base">
                    {{ customerCount }} 人
                    <div class="secondary--text text-xs">
                      来店人数
                    </div>
                  </v-col>
                  <v-col cols="6" class="font-weight-semibold text-base">
                    {{ `¥${customerCount && Math.round((salesAmount / customerCount)).toLocaleString()}` }}
                    <div class="secondary--text text-xs">
                      平均客単価
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="3"
        cols="12"
      >
        <v-card>
          <v-card-text>
            <div class="d-flex align-center">
              <v-avatar
                class="v-avatar-light-bg accent--text justify-center mr-4"
                size="40"
                rounded
              >
                <v-icon
                  size="30"
                  color="accent"
                >
                  {{ icons.mdiGroup }}
                </v-icon>
              </v-avatar>

              <div class="w-full">
                <v-row no-gutters class="d-flex align-end">
                  <v-col cols="6" class="font-weight-semibold text-base">
                    {{ customerGroupCount }} 組
                    <div class="secondary--text text-xs">
                      来店組数
                    </div>
                  </v-col>
                  <v-col cols="6" class="font-weight-semibold text-base">
                    {{ `¥${customerGroupCount && Math.round((salesAmount / customerGroupCount)).toLocaleString()}` }}
                    <div class="secondary--text text-xs">
                      平均組単価
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="3"
        cols="12"
      >
        <v-card>
          <v-card-text>
            <div class="d-flex align-center">
              <v-avatar
                class="v-avatar-light-bg warning--text justify-center mr-4"
                size="40"
                rounded
              >
                <v-icon
                  size="30"
                  color="warning"
                >
                  {{ icons.mdiExclamationThick }}
                </v-icon>
              </v-avatar>

              <div class="w-full">
                <v-row no-gutters class="d-flex align-end">
                  <v-col
                    cols="12"
                    class="font-weight-semibold text-base"
                    style="height: 40px"
                  >
                    <template v-if="readonly">
                      {{ unguidedCustomerCount }} 組
                    </template>

                    <template v-else>
                      <v-text-field
                        v-model.number="unguidedCustomerCountTemp"
                        type="number"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        min="0"
                        suffix="組"
                        hide-details
                        class="ma-0 pa-0 pb-1 text-base unguided-customer-count-input"
                        style="width: 80px"
                      />
                    </template>

                    <div class="secondary--text text-xs">
                      返却組数
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="3"
        cols="12"
      >
        <v-card>
          <v-card-text>
            <div class="d-flex align-center">
              <v-avatar
                class="v-avatar-light-bg success--text justify-center mr-4"
                size="40"
                rounded
              >
                <v-icon
                  size="30"
                  color="success"
                >
                  {{ icons.mdiGauge }}
                </v-icon>
              </v-avatar>

              <div class="w-full">
                <v-row no-gutters class="d-flex align-end">
                  <v-col class="font-weight-semibold text-base">
                    {{ formatToPercentage(operationRate || 0) }} %
                    <div class="secondary--text text-xs">
                      稼働率
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="7"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :items="hourlyCustomerCountSummaries"
                  :headers="[
                    { value: 'beginning', text: 'TIME' },
                    { value: 'customerCount', text: '来店人数' },
                    { value: 'customerAvgCheckAmount', text: '平均客単価' },
                    { value: 'customerGroupCount', text: '来店組数' },
                    { value: 'customerGroupAvgCheckAmount', text: '平均組単価' },
                    { value: 'totalCheckAmount', text: '売上合計' },
                  ]"
                  hide-default-footer
                  disable-pagination
                  disable-sort
                  fixed-header
                  height="604"
                >
                  <!-- // NOTE: 604は単価集計と同じ高さ -->
                  <template #[`item.beginning`]="{item}">
                    {{ item.beginning.slice(0,5) }}
                    <span class="ml-1">~</span>
                  </template>
                  <template #[`item.customerCount`]="{item}">
                    <span v-if="+item.customerCount > 0">
                      {{ item.customerCount }} 人
                    </span>
                    <span v-else class="secondary--text">-</span>
                  </template>
                  <template #[`item.customerAvgCheckAmount`]="{item}">
                    <span v-if="+item.customerAvgCheckAmount > 0">
                      ¥{{ item.customerAvgCheckAmount.toLocaleString() }}
                    </span>
                    <span v-else class="secondary--text">-</span>
                  </template>
                  <template #[`item.customerGroupCount`]="{item}">
                    <span v-if="+item.customerGroupCount > 0">
                      {{ item.customerGroupCount }} 組
                    </span>
                    <span v-else class="secondary--text">-</span>
                  </template>
                  <template #[`item.customerGroupAvgCheckAmount`]="{item}">
                    <span v-if="+item.customerGroupAvgCheckAmount > 0">
                      ¥{{ item.customerGroupAvgCheckAmount.toLocaleString() }}
                    </span>
                    <span v-else class="secondary--text">-</span>
                  </template>
                  <template #[`item.totalCheckAmount`]="{item}">
                    <span v-if="+item.totalCheckAmount > 0">
                      ¥{{ item.totalCheckAmount.toLocaleString() }}
                    </span>
                    <span v-else class="secondary--text">-</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <v-card>
          <v-card-text>
            <v-data-table
              ref="tableCountsByCheckAmountRangeTable"
              :items="tableCountsByCheckAmountRange"
              :headers="[
                { value: 'range', text: '単価' },
                { value: 'count', text: '組数' },
              ]"
              hide-default-footer
              disable-pagination
              disable-sort
              fixed-header
            >
              <template #[`item.range`]="{item}">
                <span v-if="item.beginning && item.end">
                  ¥{{ item.beginning.toLocaleString() }} ~ ¥{{ item.end.toLocaleString() }}
                </span>
                <span v-else-if="!item.beginning && item.end">
                  ¥0 ~ ¥{{ item.end.toLocaleString() }}
                </span>
                <span v-else-if="item.beginning && !item.end">
                  ¥{{ item.beginning.toLocaleString() }} ~
                </span>
              </template>
              <template #[`item.count`]="{item}">
                <span v-if="item.count && item.count > 0">
                  {{ item.count }}組
                </span>
                <span v-else>
                  -
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { debounce, round } from 'lodash'
import {
  mdiAccountGroupOutline,
  mdiGroup,
  mdiExclamationThick,
  mdiPencilOutline,
  mdiGauge,
} from '@mdi/js'
import UnguidedCustomerCountApi from '@/api/admin/UnguidedCustomerCount'

export default {
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    customerCount: {
      type: Number,
      required: true,
      default: 0,
    },
    customerGroupCount: {
      type: Number,
      required: true,
      default: 0,
    },
    unguidedCustomerCount: {
      type: Number,
      default: 0,
    },
    operationRate: {
      type: Number,
      default: 0,
    },
    salesAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    hourlyCustomerCountSummaries: {
      type: Array,
      required: true,
      default: () => [],
    },
    tableCountsByCheckAmountRange: {
      type: Array,
      required: true,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const unguidedCustomerCountTemp = ref(props.unguidedCustomerCount)

    const saveUnguidedCustomerCount = debounce(async () => {
      if (props.readonly) return

      const res = await UnguidedCustomerCountApi.saveUnguidedCustomerCount({
        date: props.date,
        count: unguidedCustomerCountTemp.value,
      })

      if (res?.data) {
        vm.$toast.success('返却組数を保存しました')
        unguidedCustomerCountTemp.value = res.data.unguidedCustomerCount
      } else {
        vm.$toast.error('返却組数の保存に失敗しました')
      }
    }, 800)

    const formatToPercentage = value => {
      return Math.floor(value * 10000) / 100
    }

    watch(
      () => unguidedCustomerCountTemp.value,
      () => {
        saveUnguidedCustomerCount()
      },
    )

    return {
      // data
      unguidedCustomerCountTemp,

      // methods
      debounce,
      formatToPercentage,

      icons: {
        mdiAccountGroupOutline,
        mdiGroup,
        mdiExclamationThick,
        mdiPencilOutline,
        mdiGauge,
      },
    }
  },
}
</script>

<style lang="scss">
.hourly-customer-count-summaries-chart {
  overflow-y: scroll;
}
.hourly-customer-count-summaries-chart::-webkit-scrollbar {
  display: none;
}
.hourly-customer-count-summaries-chart {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.unguided-customer-count-input input {
  padding: 0 !important;
}
</style>
