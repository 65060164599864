<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-subtitle>
            顧客タグ別売上
          </v-card-subtitle>
          <v-card-text>
            <chartjs-component-horizontal-bar-chart
              :id="`sales-per-customer-chart-${minHeight}`"
              :data="data"
              :options="options"
              :style="{ minHeight: `${minHeight}px` }"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import useColor from '@/views/composable/useColor'
import ChartjsComponentHorizontalBarChart from '@/views/components/charts/ChartjsComponentHorizontalBarChart.vue'

export default {
  components: {
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    salesPerCustomerTag: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props) {
    const { chartColors, generateRandomColorCodeArray } = useColor()

    const minHeight = ref(500)
    onMounted(() => {
      const rowsHeight = Object.keys(props.salesPerCustomerTag).length * 16

      if (minHeight.value < rowsHeight) minHeight.value = rowsHeight
    })

    const data = computed(() => {
      const labels = Object.keys(props.salesPerCustomerTag)

      return {
        labels,
        datasets: [
          {
            data: Object.values(props.salesPerCustomerTag),
            backgroundColor: generateRandomColorCodeArray(labels.length),
            borderColor: 'transparent',
            barThickness: 15,
          },
        ],
      }
    })

    const options = computed(() => {
      return {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'top',
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                zeroLineColor: chartColors.grid_line_color,
                borderColor: 'transparent',
                color: chartColors.grid_line_color,
                drawTicks: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                min: 0,
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      }
    })

    return {
      data,
      options,
      minHeight,
    }
  },
}
</script>
